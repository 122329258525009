.Login #Login{
    background:white;
    min-width: 300px;
    width: 30vw;
    padding: 2vw;
    border-radius: 5px;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 50%;
    transform: translate(-50%,0);
    margin-top: 2vw;
}

.Login #ifo{
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%,0);
    color:rgba(255, 255, 255, 0.6) !important;
    backdrop-filter: blur(10px);
    background: rgba(50,50,50,0.1);
    padding: 5px 30px;
    border-radius: 5px;
    

}

.Login #ifo a{
   
    color:rgba(255, 255, 255, 1) !important;

}