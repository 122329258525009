.Folder{
    width: 6vw;
    overflow: hidden;
    border-radius: 5px;
    min-width: 100px;
    cursor: pointer;
   position: relative;
    transition: .3s;
    transform-origin: bottom;
    text-rendering: optimizelegibility;
    box-shadow: 5px 5px 30px rgba(0,0,0,0);
}
.Folder img{
    width: 100%;
    height: 4vw;
    min-height: 70px;
    
    object-fit: cover;
    display: block;
    border-radius: 5px;
}

.Folder p{
    font-size:11.5px;
    margin:0;
    text-align: center;
    line-height: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    text-rendering: optimizelegibility;
    color: rgba(0, 0, 0, 0.473);
    transition: .3s;
}

.Folder.Selected{
    box-shadow: 5px 10px 30px rgba(0,0,0,0.08) !important;
    transform: scale(1.1) !important;
    z-index: 4;
}
.Folder.Selected p{
    color: black;
}
.Folder:hover p{
    color: black;
}