.POP_Folder #PrevImg_img_FOLDER{
   border-radius: 5px; 
   margin-bottom: 10px;
   background-size: cover !important;
   background-position: center;
}
.POP_Folder #PrevImg_cont .filePrev{
margin-bottom: 0px;
line-height: 100px;
color: white !important;
background: rgba(100,100,100,.3);
backdrop-filter: blur(3px);
transition: .3s;
}
.POP_Folder #PrevImg_cont .filePrev:hover{
    background: rgba(100,100,100,.8);
}