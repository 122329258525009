.AddFolder{
    width: 6vw;
    overflow: hidden;
   
    min-width: 100px;
    cursor: pointer;
   position: relative;

   min-height: 70px;
    height: 4vw;
    
    transform-origin: bottom;
    text-rendering: optimizelegibility;
    border:4px rgb(185, 195, 209) solid;
    border-radius: 7px;
}
.AddFolder p{
    opacity: 0;
    transition: .3s;
    font-size: 12px;
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
    margin-top: -5px;
    color: rgb(53, 64, 80);
    
}
.AddFolder:hover p{
    opacity: 1;
}
.AddFolder:hover h1{
    opacity: 1;
    transform: translate(0,0px);
}

.AddFolder h1{
    transition: .3s;
   transform: translate(0,8px);
    text-align: center;
    margin: 0;
    color: rgb(161, 174, 192);
    font-weight: 600;
    
}