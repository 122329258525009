.Zaiavki{
    overflow-y: auto;
}

.Zaiavki .zay{
    height: max-content;
    font-size: 14px;
   
}

.Zaiavki .zay .body{
    height: 100%;
    background: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 5px 13px 30px rgba(0,0,0,0.03);
    overflow: hidden;
    transform: scale(1.01);
    position: relative;
    z-index: 1;
    transition: .3s;
}

.Zaiavki .scroll{
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
}

.Zaiavki .table{
    font-size: 12px;
    line-height: 13px;
    flex: none;
    width: auto;
    max-width: 90000px;
}

.Zaiavki .table  img{
    transition: .3s;
}

.Zaiavki .table .open img{
    transform: scaleY(-1);
}

.Zaiavki .table td{
    max-width: 160px;
    border-right: 1px solid rgba(0,0,0,0.02);
}

.Zaiavki .table .main{
    background: #f7f8f9;
}

.Zaiavki thead {
    font-weight: 700;
}

.Zaiavki .zay .body:hover{
    transform: scale(1.05);
    box-shadow: 5px 13px 30px rgba(0,0,0,0.08);
}


.Zaiavki .zay .status{
    padding-top: 20px;
    padding-bottom: 5px;
    text-align: center;
    margin-top: -16px;
    position: relative;
    border-radius:5px;
    font-size: 12px;
    z-index: 0;
    font-weight: 500;
    letter-spacing: 1px;
    color:white;
}

.Zaiavki .zay h4{
    font-size: 18px;
}

.Zaiavki .zay .row{
    border-top: 1px solid rgb(250, 250, 250);
    padding: 10px 0px;
    margin-left: 0px;
    margin-right: 0px;
    cursor: pointer;
    transition: .3s;
}

.Zaiavki .zay .row:hover{
background: rgb(251 252 255);
}

.Zaiavki .zay small{
    display: block;
    margin-top: -7px ;
    color: gray;
    margin-bottom: 15px;
}

.Zaiavki .zay-title{
    margin-bottom: 0px;
    
}


.Zaiavki .zay-st{
    height: 10px;
    width: 10px;
    border-radius: 100px;
    float: right;
    margin-top: 5px;
    transition: .3s;
}
.Zaiavki .table .zay-st {
    width: max-content;
    padding: 0px 10px;
    height: 14px;
    margin-top: 0px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    display: block;
    float: none;
    color:white;
    font-size: 11px;
}

.Zaiavki .scroll {
    overflow-y: auto;
    overflow-x: auto;
}

 .st-1{
    background-color: rgb(150,155,176) !important;
   
}
 .st-2{
    background-color: rgb(176,157,150) !important;
   
}
 .st-3{
    background-color:rgb(192 179 85) !important;
   
}

 .st-4{
    background-color:rgb(0, 133, 11) !important;
   
}
 .st-5{
    background-color: rgb(130, 192, 129)
   
}
 .st-6{
    background-color: rgb(192 ,129, 129) !important;
   
}
.st-7{
    background-color: rgb(172, 172, 172) !important;
    
}


.si-,.si-null{
    background-color: rgb(150,155,176) !important;
}
.si-1{
    background-color: rgb(192 ,129, 129) !important;
}

.si-2{
    background-color: rgb(192, 157, 129) !important;
}
.si-3{
    background-color: rgb(212, 203 ,103) !important;
}
.si-4{
    background-color: rgb(130, 192, 129)  !important;
}



.Zaiavki_zaka .main_pages .left{
    overflow-y: scroll;
    height: 100%;
}

.Zaiavki_zaka .left #smoller {
    padding-right: 0;
    position: relative;
    margin-top: 10px !important;
    margin-bottom: 15px;
    bottom: 0;
}










.Zaiavki_zaka .left .obj b{
    margin-bottom: 5px;
    display: block;
    padding: 10px;
    margin-left: -10px;
    cursor: pointer;
    margin-right: -10px;
    transition: .3s;
    font-size: 12px;
    font-weight: 700;
    margin-top: 0px;

    margin-top: 19px;
    transform: translate(0,-50%);
    margin-bottom: -21px;
}

.Zaiavki_zaka .left .obj b.active{
    background: rgb(150,155,176) !important;
    color:white;
    padding-left: 15px;
}


.Zaiavki_zaka .left .obj p{
    margin-bottom: 5px;
    display: block;
    padding: 10px;
    margin-left: -5px;
    cursor: pointer;
    margin-right: -5px;
    border-radius: 10px;
    transition: .3s;
    font-size: 13px;
    margin-top: 0px;
    padding-left: 15px;
}
.Zaiavki_zaka .left .obj p:hover{
    padding-left: 20px;
}
.Zaiavki_zaka .left .obj p.active{
    background: rgb(249 250 254);
    padding-left: 20px;
}

.Zaiavki_zaka .left .obj{
    padding: 15px;
    border-radius: 10px;
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 10px;
    transition: .3s;
    padding-bottom: 5px;
    background: rgba(255, 255, 255, 0.3);
}

.Zaiavki_zaka .left .obj .btn{
    opacity: 0;
    transition: .3s;
    font-weight: 800 !important;
    font-size: 17px;
    position: relative;
    z-index: 10;
}



.Zaiavki_zaka .left .obj.active .btn{
    opacity: 1;
}


.Zaiavki_zaka .left .obj{
    overflow: hidden;
}

.Zaiavki_zaka .left .obj.active{
    background: white !important;
}
.Zaiavki_zaka .left .obj hr{
    opacity: 0;
    margin-top: -2px;
    margin-bottom: 2px;
    transition: .3s;
}
.Zaiavki_zaka .left .obj.active hr{
    opacity: 0.3;
}



.Z_object textarea{
    resize: none;
    height: 100px !important;
}