.DataBase .SPR_TYPES{
    background: rgba(255, 255, 255, 0.3);
    padding: 15px;
    border-radius: 10px;
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 10px;
    transition: .3s;
    padding-bottom: 5px;
    margin-top: 0;
    margin-bottom: 5px;
    cursor: pointer;
    transition: .3s;
    font-size: 14px;
}

.DataBase .SPR_TYPES.active{
    background:white;
}

.DataBase .scroll{
    overflow-y: auto;
}



.DataBase .SPR_DATA {
  
    height: 40px;
    display: block;
    border: none;
    padding: 0;
    margin-bottom: 0;
    padding-left: 20px;
    margin-top: 10px;
    transition: .2s;
    line-height: 34px;
}
.DataBase .SPR_DATA:hover{
    padding-left: 30px;
}

.DataBase .scroll hr{
    margin-left: 15px;
    margin-right: 15px;
    opacity: 0.1;
    margin-top: 0;
    margin-bottom: 0;
}


.DataBase .scroll input.nullText{
    
    border: #d79b9b 1px solid;
}