.card-body .POP_reqv.POP_reqv_messages{
    width: 700px !important;
    
}

.POP_reqv_messages .msg{
   
    border-radius: 10px;
    
    padding: 10px;
    max-width: 70%;
    margin-bottom: 10px;
}

.POP_reqv_messages .msg.from{
    border-top-left-radius: 0px;
    border:1px solid #ececec;
    background: #f8f8f8;
}

.POP_reqv_messages .msg.to{
    border-top-right-radius: 0px;
    background: #dbe4e9;
    border:1px solid #c6d1e1;
    margin-left: auto;
}

.POP_reqv_messages .scroll{
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    
    min-height: 300px;
}

.POP_reqv_messages hr{
    margin-top: 0;
}

.POP_reqv_messages .scroll .msg p{
    font-size: 13px;
    margin: 0px;
    opacity: 1;
    line-height: 14px;
}
.POP_reqv_messages .msg small{
    font-size: 12px;
    margin: 0;
    padding: 0;
    display: block;
    opacity: 0.6;
}

.POP_reqv_messages .scroll p{
    font-size: 13px;
    opacity: 0.3;
    margin: 0;
    margin-top: 20px;
}

.POP_reqv_messages .buts img{
    height: 30px;
    width: 30px;
    cursor: pointer !important;
    transition: .3s;
    margin-left: auto;
    margin-right: 10px;
    display: block;
    margin-bottom: 10px;
}
.POP_reqv_messages .buts label{
    margin-left: auto;
    display: block;
    width: max-content;
    margin-top: 15px;
}

.POP_reqv_messages .buts img:hover{
    transform: scale(1.2);
}
.POP_reqv_messages textarea{
    height: 80px !important;
    resize: none;
}
.POP_reqv_messages textarea:active,.POP_reqv_messages textarea:focus{
    box-shadow: none;
    border: none;
}




.POP_reqv_messages .addedFile{
    width: 59px;
    height: 30px;
    display: block;
    margin-bottom: 20px;
    font-size: 9px;
    margin-top: -5px;
}


.POP_reqv_messages .addedFile img{
    width: 20px;
    height: 20px;
    display: block;
    margin-left: auto;
    margin-top: 2px;
}

.POP_reqv_messages .addedFile.to{
    margin-left: auto;
    margin-right: 5px;
    
}

.POP_reqv_messages .addedFile.from img{
    margin-right: auto;
    margin-left: 0;
}