.Otchot .weeks td{
    font-weight: 400 !important;
    font-size: 11px !important;
    
}
.Otchot .mainLabels td{
    min-width: 84px  !important;
    text-align: center;
    height: 89px;
    white-space: inherit !important;
}

.Otchot .weeks{
    height: 51px;
}

.fw-light{
    font-weight: 400 !important;
}
.Zaiavki.Otchot .table.CONTS_OTCH tr{
    width: max-content;
}
.Zaiavki.Otchot .table.CONTS_OTCH td{
    width: max-content;
    max-width: 999px;
    white-space: nowrap;
}

.Zaiavki.Otchot table .timeLine{
    width: 100%;
    transition: 0s !important;
    height: 24px;
    margin-top: 7px;
    border-radius: 7px;
    line-height: 22px;

}


.Otchot .timeLies td{
    height: 39px;
    padding: 1px;
}

.Otchot .bg-primary{
    background: #969bb0  !important;
    color:white;
}