.Contacts .right{
    background: white;
}
.Contacts .left{
    backdrop-filter: blur(13px);
    background: rgba(255,255,255,0.3);
}

.Contacts .input-group-text{
    min-width: 81px;
    font-size: 14px;
}

.Contacts input,.Contacts textarea{
    background: rgba(255,255,255,0.4);
    font-size: 14px;
}
.Contacts textarea{
    height:70px!important;
    resize: none;
}

.Contacts #docs {
    border-radius: 5px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.205);
  
    height: 25vh;
    width: 96%;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 2px;
    padding: 18px;
    padding-top: 3px;
}

.Contacts #docs .col{
    padding: 3px;
    
    height: auto;
}


.Contacts .verticalScroll{
    height: 50vh;
    margin-top: -50px;
    padding-top: 30px;
    
}
.Contacts .horizontalScroll{
    margin-left: -15px;
    margin-right: -15px;
    transform: scaleY(-1);
    z-index: 4;
    position: relative;
}
.Contacts .horizontalScroll .scrollContent .scrollElement{
    vertical-align: top;
}
.Contacts .horizontalScroll .scrollContent .scrollElement p{
    padding: 0px 5px;
}
.Contacts .horizontalScroll .scrollContent{

    transform: scaleY(-1);
    z-index: 4;
}


.Contacts #docs .col img{
    border-radius: 5px;
    
    max-width: 100%;
    max-height: 100%;
}
.Contacts #docs .col:hover  .remove{
   
    transform: scale(1);
}
.Contacts #docs  .remove{

    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: red;
  color:white;
  border:2px solid white;
  text-align: center;
  line-height: 12px;
  cursor: pointer;
  font-weight: 600;
    background-size: 13px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: -2px;
    z-index: 10;
    transition: .3s;
    top:-2px;
    transform: scale(0);
}

.Contacts #docs  .remove:hover{
    transform: scale(1.5) !important;
}


.Contacts .downButs{
    z-index: 10;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.Contacts .downButs .btn{
    background: rgba(255,255,255,0.4);
}
.Contacts .downButs .btn:hover{
    background: rgba(255,255,255,0.8);
}
.Contacts .downButs #denger:hover{
    background: rgb(204, 115, 115);
    color:rgb(107, 23, 23) !important;
}