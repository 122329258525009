.Chat,.User,.Mail,.Cont{
    background: white;
    border-radius: 5px;
    height: 56px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);;
    min-width: 163px;
    cursor: pointer;
    position: relative;
    z-index: 5;
}

.Chat img{
    width: 35px;
    height: 35px;
    display: block;
    margin: auto;
    opacity: 0.7;
    margin-top: 8px;
}

.Chat h4{
   
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-top: 8px;
    margin-left: -5px;
}


.Chat p{
    font-size: 12px;
    padding: 0;
    margin: 0;
    margin-left: -5px;
}
.Cont .btn{
    margin-top: 13px;
    background: transparent;
}

.User h4{
    font-size: 16px;
    font-weight: 600;
    margin-top: 9px;
    margin-bottom: 0px;
}
.User p{
    font-size: 14px;
    margin-top: 0px;
}

.User .text-right{
    margin-top: 14px;
}





.ChatWindow{
    z-index: 4;
    overflow: hidden;
    transition: .3s ease-in-out;
    position: fixed;
    bottom: 85px;
    width: 300px;
   
    right: 16px;
    background: white;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 0px;
    box-shadow: 5px 5px 80px rgba(0, 0, 0, 0.05);
}
.ChatWindow #title{
    margin: 0;
    padding: 0;
}
.ChatWindow hr{
    margin: 0;
    margin-top: 10px;
}

.ChatWindow .scroll{
    height: 50vh;
    padding-top: 15px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
}
.ChatWindow .chat{
    
    transition: .3s;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.ChatWindow .chat:hover{
background: #f9f9f9;
}

.ChatWindow .chat p{
    margin: 0;
    padding: 0;
    display: block;
    font-size: 14px;
}

.ChatWindow .chat small{
    margin: 0;
    padding: 0;
    display: block;
    margin-top: -5px;
    color:rgb(121, 121, 121);
    font-size: 11px;
}


.ChatWindow.false{
    height: 0px;
    bottom: 55px;
    opacity: 0;
}
.ChatWindow.true{
    height: 50vh;
}

#inds{
    width: 10px;
    height: 10px;
    background: white;
    transition: .5s;
    border-radius: 100px;
    position: absolute;
    left: 5px;
    top: 5px;
}

#inds.true{
    background: rgb(184, 69, 65);
}

@media only screen and (max-width: 1300px) {
    .Chat img{
        width: 20px;
        height: 20px;
        margin-top: 18px;
        
    }

    .Chat h4{
        margin-top: 13px;
        font-size: 14px;
       
    }
    
    .Chat p{
        font-size: 11px;
       
    }
    
    
}