div#leg {
    background: white;
    border-radius: 10px;
    position: absolute;
    font-size: 12px !important;
    padding: 20px;
    left: 10px;
    width: calc(100% - 20px);
    box-shadow: 5px 5px 30px rgba(0,0,0,0.05);
    z-index: 99;
    display: none;
}

#leg p{
    padding: 0px;
    margin: 0;
}


#Legend #bt{
    background: white;
    border-radius: 10px;
    padding: 10px 20px;
    margin-left: -5px;
    margin-right: -5px;
    font-size: 13px;
    cursor: pointer;
    text-align: center;
}
#Legend:hover #leg{
    display: block;
}