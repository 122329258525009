.top_text{
    margin-top: -8px !important;
    font-size: 14px;
}
.POP{
    width: 300px;
    max-height: 85vh;
}
.filePrev{

        display: inline-block;
        width: 100%;
        height: 8em;
        border-radius: 5px;;
        text-align: center;
    line-height: 7.5em;
    border:1px solid silver;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      
}
#root .css-3pxmmn{
    transition: 0s !important;
}