.POP_Users{
    width: 80vw;
    max-width: 1000px;
    min-width: 500px;
    min-height: 0;
    height: 550px;
    margin: -21px;
}
.POP_Users #topS{
    height: 140px;
}
.POP_Users h6{
    font-weight: 400;
    cursor: pointer !important;
    border-radius: 5px;
    padding: 10px 10px;
    transition: .3s;
    padding-top: 5px;
    
}
.POP_Users #settings h6{
    font-size: 13px;
}

.POP_Users .blocked{
    background: rgb(235, 235, 235) ;
}
.POP_Users #settings small{
    font-size: 10px !important;
}
.POP_Users #ava{
    background: url(/server/img/accounts/img_1.jpg);
    width: 100%;
    background-size: cover;
    height: 150px;
    background-position: center;
    border-radius: 10px;
}
.POP_Users #users{
    height: 550px;
    overflow: hidden;
    border-right:1px solid rgb(235, 235, 235);
}
.POP_Users h6:hover{
    background:#007bff88 ;
    
}

.POP_Users .verticalScroll{
    height: 454px
}
.POP_Users .infa {
    min-width: 67px;
    display: inline-block;
}

.POP_Users .row{
    padding:0 15px;
}
.POP_Users .scrollContent{
    padding-top:20px;
    padding-bottom: 100px;
}

.POP_Users #folders,.POP_Users #files{
    height: 317px;
}
.POP_Users .selected{
    background:#007bff !important;
    color:white;
}

@media only screen and (max-width: 1000px) {
    .POP_Users #settings p{
        font-size: 14px;
    }
    .POP_Users .infa {
        min-width: 0px;
        display: inline-block;
    }
    }

    @media only screen and (max-width: 800px) {
        .POP_Users #settings p{
            font-size: 12px;
        }
        .POP_Users h6{
            font-size: 14px;
        }
        .POP_Users button{
            font-size: 10px;
        }
        }