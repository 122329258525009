.modal_body {
    outline: none;
    background: white;
    font-size: 1.6rem;
   
    
    display: block;
    justify-content: space-between;
    flex-direction: column;
    z-index: 15;
    display: block;
    position: relative;
    
  
    will-change: transform;
    transform: translate3d(0, 0, 0);
    max-width: 70vw;
    max-height: 80vh;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
  }
  
  @media (max-width: 768px) {
   .modal_body {
      width:90vw;
     overflow-y: auto;
    }
  }