body{
  background:url(/server/img/bg.jpg);
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-repeat: no-repeat;
    /**
     * Для эксплорера
     */
    -ms-user-select: none;

    /**
     * Для мозилы
     */
    -moz-user-select: none;

    /**
     * Для конкверора
     */
    -khtml-user-select: none;

    /**
     * Для Сафари и Хрома
     */
    -webkit-user-select: none;

}
.dropdown-menu{
  max-height: 90vh !important;
  overflow: auto !important;
}


.main_pages{
  border-radius: 5px;
    overflow: hidden;
    height: 84vh;
  
   
    margin: 0px !important;
    margin-top: 15px !important;
    
}
@media only screen and (max-height: 945px) {
  .main_pages{
     height: 80vh;
  }
}


@media only screen and (max-height: 730px) {
  .main_pages{
     height: 75vh;
  }
}
@media only screen and (max-height: 585px) {
  .main_pages{
     height: 400px;
  }
}

select.form-control{
  padding: 0 !important;
    padding-left: 5px !important;
}



.form-control.form-dark{
  background: #f3f5f7 !important;
  transition: .2s !important;
  border-color:#f3f5f7 ;
}
.form-dark:hover, .form-dark:focus{
  background: #e5e9ec !important;
}
.css-3pxmmn{
  z-index: 21 !important;
}
::-webkit-scrollbar { width:10px; height: 25px; cursor: pointer;background:rgb(90, 103, 126);  }
::-webkit-scrollbar-button {  background-color: transparent; width: 5px; height: 5px; }
::-webkit-scrollbar-track {  background-color: #999; ;}
::-webkit-scrollbar-track-piece { background-color: rgba(240, 240, 240, 1);}
::-webkit-scrollbar-thumb { background:rgb(90, 103, 126); border-radius: 30px; border:6px solid rgb(200, 200, 200); }
::-webkit-scrollbar-corner { background-color: #999;}
::-webkit-resizer { background-color: #666;}


.tttb  ::-webkit-scrollbar { width:5px; height: 25px; cursor: pointer; }

.horizontalScroll{
  overflow-x: auto;
  overflow-y: hidden;
  
}
body .btn-group .btn{
  font-size: 11px;
}
body textarea.form-control{
  height: 100px !important;
  resize: none !important;
}

html .btn-danger{
  background-color: rgba(221, 53, 69, 0.17);
}
.input-group-text{
  font-size: 14px !important;
}
.input-group-prepend,.form-control{
  height: 30px !important;
  font-size: 14px !important;
  -webkit-appearance:none;
}
.horizontalScroll .scrollContent{
  width: max-content;
}
.verticalScroll{
  overflow-x: hidden;
  overflow-y: auto;
}
.horizontalScroll .scrollContent{
  height: max-content;
}
.horizontalScroll .scrollContent .scrollElement{
  display: inline-block;
  margin:5px;
}

.dropdown-menu{
  
}

.h-200px{
  height: 200px !important;
}
.h-150px{
  height: 150px !important;
}

#MAIN_title h1{

  font-size: 3vw;
  font-weight: 600;
  color:#23272d;
  margin-left: 5vw;
  margin-top: 10vh;
}
#MAIN_title p{

  font-size: 2vw;
  font-weight: 300;
  margin-top: -10px;
  color:#23272d;
  margin-left: 5vw;
}

h1,h2,h3,h4,h5,p,a{
  font-family: "Roboto";
}

body .btn-primary-grad{
  background: linear-gradient(235deg, rgba(116,170,183,1) 0%, rgba(161,200,210,1) 100%);
  border: 0px rgba(116,170,183,1) solid;
}

body .btn-primary{
  background: #dff0f7;
  border: 0px rgba(116,170,183,1) solid;
}
body .btn-primary:hover{
  background: #b6d2df;
}
body .btn-primary:active,body .btn-primary:focus{
  background-color: #8fb0c0 !important;
 
  
}
body .btn:active,body .btn:focus{
  box-shadow: none !important;
}

body .btn{
  border: 0px rgba(116,170,183,1) solid;
  color:rgba(0,0,0,0.6) !important;
}

body .btn:hover{
  
  color:rgba(0,0,0,1) !important;
}

