.PageTitle img{
    width: 22px;
    height: 22px;
    display: inline-table;
    vertical-align: middle!important;
}

.PageTitle p{
    display: inline-table;
    vertical-align: middle!important;
    font-size: 13px;
    margin-top: 20px;
    line-height: 13px;
    font-weight: 500;
}