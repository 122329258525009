.Header .container-fluid{
    background:white;
}
.Header{
    height: 70px;
}

.Header #menuButs img{
width: 20px;
height: 20px;
display: inline-table;
vertical-align: middle!important;
transition: .3s;;
}
.Header #menuButs p{
  font-size: 13px;
    display: inline-table;
    line-height: 15px;
    vertical-align: middle!important;
    margin-top:16px;
    height: 20px;
    transition: .3s;

}

.Header #menuButs .col{
    padding: 0;
    min-width: 120px;
    cursor: pointer;
    border-radius: 5px;
}
.Header #menuButs .col:hover p{
    transform: translate(5px,0);
}
.Header #menuButs .col:hover img{
    transform: scale(1.5);
}
.Header #headerLogo{
    
    display: block;
    margin:auto;
    margin-top: 5px;;
   
}

.Header #cont img{
    width: 16px;
    margin-top: -4px;
    opacity: 0.5;
}
    
@media only screen and (max-width: 1500px) {
    .Header #exit1{
        display: none;
    }
}

@media only screen and (max-width: 1210px) {
    .Header #menuButs p{
        font-size: 11px;
    }
    .Header #menuButs .col{
      
        min-width: 103px;
    }
    
    .Header #headerLogo{
        width: 25px;
        margin-top: 20px;
    }
}
    

@media only screen and (max-width: 1050px) {
  
    .Header #menuButs .col:hover p{
        transform: translate(0,0);
    }
    .Header #headerLogo_cont{
        display: none;
      }
    .Header #menuButs .col{
      
        min-width: 104px;
    }
    .Header #menuButs img{
       display: block;
       margin: auto; 
    }
    .Header .container-fluid{
        padding-top: 10px !important;
        padding-bottom: 4px !important;
    }

    .Header #cont{
        display: none;
    }

    .Header #menuButs p{
        
     
        text-align: center;
       width: 100%;
     
        font-size: 11px;
      }
}

@media only screen and (max-width: 1050px) {
    .Header #menuButs .col{
      
        min-width: 24px;
    }
    .Header #menuButs p{
        opacity: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        background: white;
        transition: .3s;
        margin-top: 15px;
        padding: 5px;
        border-radius: 5px;
        font-size: 11px;
        z-index: 10;
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);;
      }
      .Header #menuButs .col:hover p{
        opacity: 1;
        margin-top: 10px;;
      }

      .Header .container-fluid{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
   
   
}