.Accaunt .left{
    backdrop-filter: blur(13px);
    background-color: rgba(255,255,255,0.3);
    background-image: url('/server/img/user_white.svg');
    background-size: cover;
    background-position: center;
    background-size: 100px;
    background-repeat: no-repeat;
    
}
.Accaunt .right{
 
    background: rgba(255,255,255,1);
}


.Accaunt .input-group-text{
    width: 190px;
    font-size: 12px !important;
}

.Accaunt #PrevImg_cont{
    position: absolute;
    z-index: 3;
    bottom: 15px;
    width: 100%;
    padding-right: 30px;;
    height: 30px;
}

.Accaunt #PrevImg{
   width: 100%;
   height: 30px;
background: rgba(255,255,255,0.5);
backdrop-filter: blur(10px);
line-height: 19px;
}

.Accaunt #PrevImg:hover{
    background: white;
}