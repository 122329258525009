.POP_reqv{
    width: 100% !important;
}

.card-body .POP_reqv{
    width: 960px !important;
}


.card-body  .POP_reqv_cont{
    width: 870px !important;
}

.card-body  .POP_reqv_item{
    width: 400px !important;
}

.POP_reqv textarea.t1{
    height: 150px !important;
    resize: none;
}

.POP_reqv textarea.t2{
    height: 80px !important;
    resize: none;
}

.POP_reqv .right{
    border-left: 1px rgb(241, 241, 241) solid;
}



.POP_reqv .scroller{
    font-size: 13px;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100px;
    margin: -5px;
    padding: 5px;
}

.POP_reqv .right .scroller{
    margin: -15px;
    padding: 15px;
}


.POP_reqv .scroller .row{
    width: 100%;
    display: inline-block;
    border-radius: 5px;
    background: white;
    transition: .3s;
    position: relative;
    z-index: 1;
    margin: 0px;
    box-shadow: 5px 5px 30px rgba(0,0,0,0.02);
    padding: 5px 0px;
    text-align: center;
    cursor: pointer;
}

.POP_reqv .scroller .row:hover{
    transform: translate(-5px,0);
    box-shadow: 10px 5px 30px rgba(0,0,0,0.04);

}

.POP_reqv .scroller .row div{
    display: inline-block;
    vertical-align: middle;
}
.POP_reqv .scroller .row .col-7{
    text-align: left;
}
.POP_reqv .scroller .zaya{
    transition: .3s;
    position: relative;
    margin-bottom: 10px !important;
}

.POP_reqv .scroller .zaya .status{
    
    width: 30px;
    height: 99%;
    position: absolute;
    right: -4px;
    border-radius: 5px;
    top: 0px;
    z-index: 0;

}

.POP_reqv input:disabled,.POP_reqv textarea:disabled,.POP_reqv select:disabled{
    background: #fdfdfd !important;
    border: white !important;
}