
._1 .left{
    backdrop-filter: blur(13px);
    background: rgba(255,255,255,0.3);
}
._1 .right{
 
    background: rgba(255,255,255,1);
}

._1 #pg-viewer{
    height: 200px;
    border-radius: 10px;
    width: 100%;
    overflow: scroll;
    background-color: rgba(36, 36, 36, 0.17);
    margin-bottom: 10px;
    cursor: pointer;
}

._1 #pg-viewer .document-container{
    transform: scale(0.5);
    border-radius: 20px;
overflow: hidden;
transform-origin: left top;
}

._1 .verticalScroll{
    height: calc(100vh - 360px);
    margin-top: -50px;
    padding-top: 30px;
    
}
._1 .horizontalScroll{
    margin-left: -15px;
    margin-right: -15px;
    transform: scaleY(-1);
    z-index: 4;
    position: relative;
}
._1 .horizontalScroll .scrollContent .scrollElement{
    vertical-align: top;
}
._1 .horizontalScroll .scrollContent .scrollElement p{
    padding: 0px 5px;
}
._1 .horizontalScroll .scrollContent{

    transform: scaleY(-1);
    z-index: 4;
}
._1 #smoller .btn-light{
    background: rgba(255,255,255,0.1);
    color:rgba(255, 255, 255, 0.733) !important;
}
._1 #smoller .btn-light:hover{
    background: rgba(255,255,255,1);
    color:black !important;
}

._1 #file_folders{
    opacity: 1;
    transition: .2s;
}
._1 #file_folders.loading{
    opacity: 0;
}
._1 #backer{
    font-size: 12px;
    color:rgba(0,0,0,0.5);
    transition: .3s;
    cursor: pointer !important;
    margin-top: -34px;
    z-index: 6;
    position: relative;
    margin-left: 15px;
}
._1 #backer:hover{
    color:rgba(0,0,0,1);
    transform: translate(-5px,0);
}
._1 #deleter{
    background: rgba(138, 24, 24,0.1);;
    color:rgb(138, 24, 24) !important;
}
._1 #deleter:hover{
    background: rgb(138, 24, 24);
    color:white !important
}

._1 .whiteGrad{
    width: 100%;
    position: relative;
    z-index: 3;
    height: 15px;
}
._1 .whiteGrad.TOP{
    height: 50px;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
    margin-top: -50px;
}
._1 .whiteGrad.BOTTOM{
    margin-top: -15px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%);
}
._1 .left .fileInfo p{
    font-size: 14px;
    
}
._1 .left #smoller{
    margin-left: 0px;
    padding-right: 30px;
    position: absolute;
    bottom: 15px;
    width: 100%;
}
._1 .left #smoller #upload{
  
    width: 100%;
}

