.Footer{
    height: 7vh;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 11;;
}

@media only screen and (max-height: 915px) {
    .Footer{
       height:8vh;
    }
}


@media only screen and (max-height: 730px) {
    .Footer{
       height: 10vh;
    }

    
   
}

@media only screen and (max-width: 770px) {
  
    .d-sm-none{
        display: none !important;
    }
   
}
@media only screen and (max-height: 585px) {
    .Footer{
        height: 60px;
    }
}